import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import PaymentReceivedModal from './PaymentReceivedModal'
import PaymentDeleteModal from './PaymentDeleteModal'
import PaymentResendModal from './PaymentResendModal'
import RefundsModal from './RefundsModal'

import ModalTrigger from '../../common/modals/ModalTrigger'
import { Button } from 'reactstrap'

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'

import { Tooltip } from 'antd'

import Table from '../../common/tables/Table'
import Column from '../../common/tables/Column'
import { checkTableData, checkPermission, utcToLocal } from '../../common/utils/helpers'
import { connectResource } from '../../common/utils/resource'
import { selectPaymentTypesData } from '../../store/reducers/paymentTypes/paymentTypesSelectors'

import analytics from '../../../helpers/segment'

import { downloadFile } from './PaymentSummary'
import { isNumber } from 'lodash'
import {
  DAYS_ALLOWED_REFUND_AFTER_SUBMISSION,
  REFUND_BUTTON_TOOLTIP_TEXT,
  PaymentMethod,
} from './constants'


class PaymentsReceived extends Component {
    state = {
      hovered: false,
    }

    deletePayment = (item) => {
      this.props.consumerPayment.remove({
        order_id: this.props.orderData.id,
        payment_id: item.id,
      }).then(this.props.refreshOrder)
    }

    refundPayment = (item) => {
      this.props.refundPaymentEndpoint.create({
        order_id: this.props.orderData.id,
        payment_id: item.id,
      }).then(
        this.props.refreshOrder
      )
    }

    resendPayment = (item) => {
      const {
        currentUserId,
      } = this.props

      if (item.payment_type === 'lender') {
        this.props.payForAppraisalResend.post({
          user_id: currentUserId,
          order_id: this.props.orderData.id,
        }).then(link => {
          window.open(link, '_blank')
          this.props.refreshOrder()
        })
      } else {
        this.props.consumerPaymentResend.post({
          order_id: this.props.orderData.id,
          payment_id: item.id,
          consumer_id: item.consumer,
        }).then(this.props.refreshOrder)
      }
    }

    getConsumerFromOrderData = (consumerId) => {
      const {
        orderData,
      } = this.props

      const consumers = orderData.schedule.consumers
      const consumername = consumers.find(c => c.id === consumerId)
      if (consumername) {
        return consumername.name
      } else {
        return consumerId
      }
    }

    // capitalize the first letter of a string
    capitalize = (string) => {
      return string.charAt(0).toUpperCase() + string.substring(1)
    }


    renderRefundStatusIcons = (refunds) => {
      return (
        <div className="d-flex flex-row flex-wrap">
          {refunds.map(refund => {
          // create refund text
            const type = this.capitalize(refund.payment_type)
            const status = this.capitalize(refund.external_refund_status)
            const amount = refund.amount
            let popup_text = `${status}: ${type} refund for $${(amount / 100).toFixed(2)}.`

            // make refunds with a valid proof of refund file fancy and clickable
            let fancy = false
            let clickHandler = () => {}
            if (refund.proof_of_refund) {
              popup_text += ' Click icon to download proof of refund.'
              clickHandler = () => this.downloadProofOfRefund(refund.proof_of_refund)
              fancy = true
            }

            return this.refundStatusIcon(popup_text, clickHandler, fancy)
          })}
        </div>
      )
    }

    refundStatusIcon = (popup_text, clickHandler = null, fancy = false) => {
      const { hovered } = this.state
      // the fancy icon turns green on hover
      if (fancy) {
        const toggleHover = () => {
          this.setState(prevState => ({
            hovered: !prevState.hovered,
          }))
        }
        return (
          <Tooltip placement='top' title={popup_text}>
            <CheckCircleOutlined
              className={hovered ? 'm-1 fancy-icon fancy-icon-green' : 'm-1 fancy-icon fancy-icon-gray'}
              onClick={clickHandler}
              onMouseEnter={toggleHover}
              onMouseLeave={toggleHover} />
          </Tooltip>
        )
      } else {
        return (
          <Tooltip placement='top' title={popup_text}>
            <InfoCircleOutlined className='m-1 text-muted' onClick={clickHandler} />
          </Tooltip>
        )
      }
    }

    getPaymentRefundAmount = (payment) => {
      let total_refunded = 0
      if (payment.refund) {
        total_refunded += payment.refund.amount
      }
      if (payment.refunds) {
        payment.refunds.forEach(refund => {
          total_refunded += refund.amount
        })
      }
      return total_refunded
    }

    getMaxRefundAmount = (payment) => {
      return payment.amount - this.getPaymentRefundAmount(payment)
    }

    downloadProofOfRefund = (proof_of_refund) => {
      if (!proof_of_refund.documents || proof_of_refund.documents.length === 0) {
        return
      }
      const filename = proof_of_refund.documents[0].document_name
      const evault_id = proof_of_refund.id
      const document_id = proof_of_refund.documents[0].document_id
      const url = `${API2_URL}evault/${evault_id}/${document_id}`
      downloadFile(url, filename)
    }

    getPaymentList = () => {
      const {
        orderData,
      } = this.props
      const payments = orderData?.schedule.payments || []
      return payments
    }

    hideRefundButton = (paymentMethod) => {
      const { currentUser, orderData } = this.props

      if (currentUser.is_internal_reggora_user || paymentMethod === PaymentMethod.LENDER_DIRECT) {
        return false
      } else if (
        isNumber(orderData.days_after_submission) &&
        orderData.days_after_submission >= DAYS_ALLOWED_REFUND_AFTER_SUBMISSION
      ) {
        return true
      }
    }

    getRefundButtonTooltipTitle = (paymentMethod) => {
      const { currentUser, orderData } = this.props

      if (currentUser.is_internal_reggora_user || paymentMethod === PaymentMethod.LENDER_DIRECT) {
        return null
      } else if (isNumber(orderData.days_after_submission)) {
        return REFUND_BUTTON_TOOLTIP_TEXT
      }
    }

    render() {
      const {
        orderData,
        loanData,
        refreshOrder,
        use_up_to_amount,
        currentUser,
      } = this.props

      return (
        <div className='card mb-3'>
          <div className='card-body'>
            <h5 className='card-title w-100 d-flex justify-content-between'>Payments Received
              <ModalTrigger
                component={PaymentReceivedModal}
                consumers={loanData.consumers}
                order_id={orderData.id}
                currentUserId={this.props.currentUserId}
                refreshOrder={refreshOrder}
                use_up_to_amount={use_up_to_amount}
                borrower_payment_amount={loanData.borrower_payment_amount}
                disclosed_amount={loanData.disclosed_amount}
                default_amount={orderData.payment_summary.total_due}
                user_payment_types={this.props.userPaymentTypes}
                analytics_track_component={analytics.trackComponent}
                analytics_data={currentUser}
              >
                <Button
                  className='order-header-button btn-sm'
                  disabled={this.props.disabled || !this.props.userPaymentTypes.length || !checkPermission('payments_received_administer')}
                >
                    Create Payment
                </Button>
              </ModalTrigger>
            </h5>
            <Table striped list={{ results: this.getPaymentList() }}>
              <Column
                title='Payer'
                field='cardholder_name'
                format={(value, item) => {
                  if (item.payment_type === 'manual') {
                    return this.getConsumerFromOrderData(item.consumer)
                  } else {
                    return value || this.getConsumerFromOrderData(item.consumer)
                  }
                }}
              />
              <Column
                title='Amount'
                field='amount'
                format={value => value ? `${orderData.fields_awaiting_consumers && (orderData.fields_awaiting_consumers.includes('integration_fee') || orderData.fields_awaiting_consumers.includes('order_created')) ? 'Pending' : '$' + parseFloat(value / 100).toFixed(2)}` : 'N/A'}
              />
              <Column
                title='Refunds'
                field='refunds'
                format={(value, item) => {
                  return (
                    <div>
                      <div style={{ whiteSpace: 'nom-1 wrap' }}>{ `$${(this.getPaymentRefundAmount(item) / 100).toFixed(2)}`}</div>
                      {this.renderRefundStatusIcons(item.refunds || [])}
                    </div>
                  )
                }
                }
              />
              <Column
                title='Errors'
                field='errors'
                align='center'
                format={errors => {
                  if (!errors) {
                    return null
                  }
                  return errors.map((error, index) => {
                    const {
                      created,
                      status_code,
                      message,
                    } = error

                    const date = utcToLocal(created)

                    // form hover text
                    let popup_text_line_1
                    if (status_code && status_code !== 'None') {
                      popup_text_line_1 = `Error ${status_code}: ${message}`
                    } else {
                      popup_text_line_1 = `Error: ${message}`
                    }

                    const popup_text_line_2 = `${date.format('MMMM Do YYYY, h:mm:ss a')}`

                    return (
                      <Tooltip key={index} placement='top' title={
                        <div className="d-flex flex-column">
                          <span>{popup_text_line_1}</span>
                          <span>{popup_text_line_2}</span>
                        </div>
                      }>
                        <ExclamationCircleOutlined className='m-1 red' />
                      </Tooltip>
                    )
                  })
                }
                }
              />
              <Column
                title='Sent'
                field='created'
                format={value => checkTableData(value)}
              />
              <Column
                title='Captured'
                field='card_information_captured'
                align='center'
                width={'10%'}
                format={(value) => {
                  return value ? <CheckCircleOutlined className='green' /> : <CloseCircleOutlined className='red' />
                }}
              />
              <Column
                title='Paid'
                field='paid'
                align='center'
                width={'6%'}
                format={(value) => {
                  return value ? <CheckCircleOutlined className='green' /> : <CloseCircleOutlined className='red' />
                }}
              />
              <Column
                title='Receipt'
                field='receipt_url'
                format={value => value && <a href={value} target='_blank' rel="noopener noreferrer">Download Receipt</a>}
              />
              <Column
                title=''
                field='paid'
                align='right'
                format={(value, item) => {
                  if ((!value && item.payment_type !== 'lender') || item.payment_type === 'manual') {
                    return <ModalTrigger
                      component={PaymentReceivedModal}
                      borrower_payment_amount={loanData.borrower_payment_amount}
                      disclosed_amount={loanData.disclosed_amount}
                      currentUserId={this.props.currentUserId}
                      consumers={loanData.consumers}
                      payment={item}
                      order_id={orderData.id}
                      editing={true}
                      refreshOrder={refreshOrder}
                      use_up_to_amount={use_up_to_amount}
                      user_payment_types={this.props.userPaymentTypes}
                      analytics_track_component={analytics.trackComponent}
                      analytics_data={currentUser}
                    >
                      <Button
                        className='order-header-button btn-sm'
                        disabled={this.props.disabled || !checkPermission('payments_received_administer')}
                      >
                        Edit
                      </Button>
                    </ModalTrigger>
                  }
                }}
              />
              <Column
                title=''
                field='resend_payment'
                align='right'
                format={(value, item) =>
                  (<ModalTrigger
                    component={PaymentResendModal}
                    payment={item}
                    resendPayment={this.resendPayment}
                    analytics_track_component={analytics.trackComponent}
                    analytics_data={currentUser}
                  >
                    <Button className='order-header-button btn-sm' disabled={
                      item.paid ||
                      item.amount <= '0' ||
                      item.payment_type === 'manual' ||
                      item.card_information_captured
                    }>Resend</Button>
                  </ModalTrigger>)
                }
              />
              <Column
                title=''
                field='payment_type'
                align='right'
                width={'9.2%'}
                format={(value, item) => {
                  if (item.paid && value !== 'manual') {
                    return <span>
                      <ModalTrigger
                        component={RefundsModal}
                        payment={item}
                        order_id={orderData.id}
                        getMaxRefundAmount={this.getMaxRefundAmount}
                        refreshOrder={refreshOrder}
                        refundPayment={this.refundPayment}>
                        <Tooltip
                          overlayInnerStyle={{ width: '220px', textAlign: 'center' }}
                          placement='topRight'
                          title={this.getRefundButtonTooltipTitle(item.payment_method)}
                        >
                          {
                            this.hideRefundButton(item.payment_method) ? null : <Button
                              className='order-header-button btn-sm'
                              disabled={this.props.disabled || !checkPermission('payments_administer_refunds') || (this.getMaxRefundAmount(item) <= 0)}
                            >
                              Refund
                            </Button>
                          }
                        </Tooltip>
                      </ModalTrigger>
                    </span>
                  } else {
                    return <ModalTrigger
                      component={PaymentDeleteModal}
                      payment={item}
                      deletePayment={this.deletePayment}
                      analytics_track_component={analytics.trackComponent}
                      analytics_data={currentUser}
                    >
                      <Button
                        className='order-header-button btn-sm'
                        disabled={!checkPermission('payments_received_administer')}
                      >
                        Delete
                      </Button>
                    </ModalTrigger>
                  }
                }}
              />
            </Table>
          </div>
        </div>
      )
    }
}

function mapStateToProps(state) {
  return {
    userPaymentTypes: selectPaymentTypesData(state),
  }
}

export default compose(
  connect(mapStateToProps),
  connectResource({
    prefetch: false,
    namespace: 'consumerPayment',
    endpoint: 'loans/payment?',
    successMessage: {
      DELETE: 'Your payment has been deleted.',
    },
    apiVersion: 2,
  }),
  connectResource({
    prefetch: false,
    namespace: 'refundPaymentEndpoint',
    endpoint: 'payment/refund',
    successMessage: {
      POST: 'Payment refunded.',
    },
    apiVersion: 2,
  }),
  connectResource({
    prefetch: false,
    namespace: 'consumerPaymentResend',
    endpoint: 'loans/payment-resend',
    successMessage: {
      POST: 'Your payment link has been resent.',
    },
    apiVersion: 2,
  }),
  connectResource({
    prefetch: false,
    namespace: 'payForAppraisal',
    endpoint: 'payment/pay-appraiser',
    successMessage: {
      DELETE: 'Your payment has been deleted.',
    },
    apiVersion: 2,
  }),
  connectResource({
    prefetch: false,
    namespace: 'payForAppraisalResend',
    endpoint: 'payment/pay-appraiser-resend',
    successMessage: {
      POST: 'Opening payment link.',
    },
    apiVersion: 2,
  }),
  connectResource({
    prefetch: true,
    async: true,
    namespace: 'paymentTypes',
    endpoint: 'payment/payment-type/:orderId',
    apiVersion: 2,
    list: true,
  }),
)(PaymentsReceived)
