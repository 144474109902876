// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalBody_4b62f2 > div:last-child {\n  margin-bottom: 0;\n}\n\n.modalBody_4b62f2 .ant-tag {\n  margin-right: 0;\n}\n\n.cardContent_43c3b5 {\n  display: flex;\n  flex-direction: column;\n}\n\n.cardContent_43c3b5 > div {\n  margin-bottom: 0.25rem;\n}\n\n.cardContent_43c3b5 .data-points-label {\n  color: inherit;\n}\n\n.tagContainer_640914 {\n  display: flex;\n}\n\n.logCardItemLabel_81e93f {\n  font-weight: 700;\n  margin-right: 8px;\n}\n\n.tagArrow_550f99 {\n  margin: 0 8px;\n}\n", "",{"version":3,"sources":["webpack://src/app/pages/orders/SubmissionsTab/ReggoraReview/ReviewLog/ReviewLogModal.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".modalBody > div:last-child {\n  margin-bottom: 0;\n}\n\n.modalBody :global(.ant-tag) {\n  margin-right: 0;\n}\n\n.cardContent {\n  display: flex;\n  flex-direction: column;\n}\n\n.cardContent > div {\n  margin-bottom: 0.25rem;\n}\n\n.cardContent :global(.data-points-label) {\n  color: inherit;\n}\n\n.tagContainer {\n  display: flex;\n}\n\n.logCardItemLabel {\n  font-weight: 700;\n  margin-right: 8px;\n}\n\n.tagArrow {\n  margin: 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": "modalBody_4b62f2",
	"cardContent": "cardContent_43c3b5",
	"tagContainer": "tagContainer_640914",
	"logCardItemLabel": "logCardItemLabel_81e93f",
	"tagArrow": "tagArrow_550f99"
};
export default ___CSS_LOADER_EXPORT___;
