// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".btn_d91820.ant-btn {\n  background: none;\n  border: 0;\n  box-shadow: none;\n  color: #005DB4;\n  font-size: 16px;\n}\n\n.btn_d91820 .ant-btn[disabled] {\n  background: none;\n  border: 0;\n}\n", "",{"version":3,"sources":["webpack://src/app/pages/orders/SubmissionsTab/ReggoraReview/ReviewLog/ReviewLog.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,SAAS;EACT,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,SAAS;AACX","sourcesContent":[".btn:global(.ant-btn) {\n  background: none;\n  border: 0;\n  box-shadow: none;\n  color: #005DB4;\n  font-size: 16px;\n}\n\n.btn :global(.ant-btn[disabled]) {\n  background: none;\n  border: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "btn_d91820"
};
export default ___CSS_LOADER_EXPORT___;
